import { Grid, Typography } from "@mui/material"
import type { orderItem as OrderItemType } from './VerificationType'

type configType = {
  service_number: string
}

const CommscodeConfig = ({ orderItem }: { orderItem: OrderItemType }) => {
  const config: configType = orderItem.config

  return <Grid container spacing={2}>
    <Grid item sm={4}>
      <Typography variant='subtitle2'>Service Number</Typography>
      <Typography sx={{ pl: 3 }}>{config.service_number}</Typography>
    </Grid>
  </Grid>
}

export default CommscodeConfig