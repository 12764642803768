import { Grid, Typography } from "@mui/material"
import type { orderItem as OrderItemType } from './VerificationType'

type configType = {
  script: string,
  voice_person?: string,
  voice_talent: string,
  voice_tone: string,
}

const ProfessionalRecordingConfig = ({ orderItem }: { orderItem: OrderItemType }) => {
  const config: configType = orderItem.config

  return <Grid container spacing={2}>
    <Grid item sm={4}>
      <Typography variant='subtitle2'>Voice talent</Typography>
      <Typography sx={{ pl: 3 }}>{config.voice_talent}</Typography>
    </Grid>
    <Grid item sm={4}>
      <Typography variant='subtitle2'>Voice tone</Typography>
      <Typography sx={{ pl: 3 }}>{config.voice_tone}</Typography>
    </Grid>
    <Grid item sm={4}>
      <Typography variant='subtitle2'>Voice person</Typography>
      <Typography sx={{ pl: 3 }}>{config.voice_person ?? '-'}</Typography>
    </Grid>
    <Grid item sm={12}>
      <Typography variant='subtitle2'>Script</Typography>
      <Typography sx={{ pl: 3 }}>{config.script}</Typography>
    </Grid>
  </Grid>
}

export default ProfessionalRecordingConfig