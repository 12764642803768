import type {LoaderFunctionArgs} from "react-router-dom"
import {json} from "react-router-dom"
import axios from 'axios'

const VerificationLoader = async ( {params}: LoaderFunctionArgs ) => {
  
  const getOrder = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/order/${params.uuid}`)
      return response.data
    }
    catch {
      throw json(
        {
          message: "Order Not Found",
        },
        { status: 400, statusText: 'Order Not Found' }
      );
    }
  }

  const response = getOrder()
  return response

}

export default VerificationLoader