import { Divider, Grid, Typography } from "@mui/material"
import type { orderItem as OrderItemType } from './VerificationType'
import { Fragment } from "react"
import CircleIcon from '@mui/icons-material/Circle';

type connectionList = {
  department: string,
  email_list: string[],
  sms_list: string[],
  key_list: string[],
  transfer_list: string[],
}

type question = {
  question: string,
  question_attibute: string,
  question_type: string
}

type configType = {
  indial_number: string,
  indial_city: string,
  sign_off: string,
  greeting_message: string,
  email_subject: string,
  full_name_required: number,
  business_name: string,
  caller_hung_up: string,
  connection_type: string,
  connection_list: connectionList[]
  additional_questions: question[]
}

const hungup = {
  sendcallswithid: 'be notified of missed calls from numbers with caller ID',
  noemptymsg: 'not be notified of missed calls',
  sendallcalls: 'be notified of all missed calls'
}

const LiveAnsweringConfig = ({ orderItem }: { orderItem: OrderItemType }) => {
  const config: configType = orderItem.config
  type hungupKeyType = keyof typeof hungup
  const hungupKey = config.caller_hung_up as hungupKeyType
  return <Grid container spacing={2}>
    <Grid item sm={6}>
      <Typography variant='subtitle2'>Indial number</Typography>
      <Typography sx={{ pl: 3 }}>{config.indial_number}</Typography>
    </Grid>
    <Grid item sm={6}>
      <Typography variant='subtitle2'>Indial city</Typography>
      <Typography sx={{ pl: 3 }}>{config.indial_city}</Typography>
    </Grid>
    <Grid item sm={6}>
      <Typography variant='subtitle2'>Greeting message</Typography>
      <Typography sx={{ pl: 3 }}>{config.greeting_message}</Typography>
    </Grid>
    <Grid item sm={6}>
      <Typography variant='subtitle2'>Business name</Typography>
      <Typography sx={{ pl: 3 }}>{config.business_name}</Typography>
    </Grid>
    <Grid item sm={6}>
      <Typography variant='subtitle2'>Sign off message</Typography>
      <Typography sx={{ pl: 3 }}>{config.sign_off}</Typography>
    </Grid>
    <Grid item sm={6}>
      <Typography variant='subtitle2'>Email subject</Typography>
      <Typography sx={{ pl: 3 }}>{config.email_subject}</Typography>
    </Grid>
    {config.additional_questions.length > 0 && <Grid item sm={6}><Typography variant='subtitle2'>Additional Question(s):</Typography>
      {config.additional_questions.map((question, key) => {
        return <Typography key={key} sx={{ display: 'flex', pl: 3, alignItems: 'center' }}><CircleIcon sx={{ width: '10px' }} />&nbsp;{question.question}</Typography>
      })}
    </Grid>
    }
    <Grid item sm={6}>
      <Typography variant='subtitle2'>Hungup calls</Typography>
      <Typography sx={{ pl: 3 }}>{hungup[hungupKey]}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='subtitle2'>Contacts</Typography>
      <Grid container sx={{ pl: 3 }} spacing={1}>
        {config.connection_list.map((connection, key) => {
          return <Fragment key={key}>
            <Grid item xs={6} sm={3}><Typography variant='subtitle2'>Department:</Typography><Typography>{connection.department}</Typography></Grid>
            <Grid item xs={6} sm={3}><Typography variant='subtitle2'>Email(s):</Typography>
              {connection.email_list.map(email => {
                return <Typography key={email}>{email}</Typography>
              })}
            </Grid>
            <Grid item xs={6} sm={3}><Typography variant='subtitle2'>SMS(s):</Typography>
              {connection.sms_list.map(sms => {
                return <Typography key={sms}>{sms}</Typography>
              })}
            </Grid>
            {config.connection_type === 'Alltel Transfer' && <Grid item xs={6} sm={3}><Typography variant='subtitle2'>Transfer(s):</Typography>
              {connection.transfer_list.map(transfer => {
                return <Typography key={transfer}>{transfer}</Typography>
              })}
            </Grid>
            }
            {config.connection_list.length > (key + 1) && <Grid item xs={12}><Divider /></Grid>}
          </Fragment>
        })}
      </Grid>
    </Grid>
  </Grid>
}

export default LiveAnsweringConfig