import { Grid, Typography } from "@mui/material"
import type { orderItem as OrderItemType } from './VerificationType'
import { Fragment } from "react"

type routing = {
  routing_number: string,
  answered_after?: number,
  routing_order_item_id?: number
}

type configType = {
  inbound_number: string,
  routing_type: string,
  routing_business_hours: routing[],
  routing_after_hours?: routing[],
  business_hour_from?: string,
  business_hour_to?: string,
  business_days?: {
    monday: number,
    tuesday: number,
    wednesday: number,
    thursday: number,
    friday: number,
    saturday: number,
    sunday: number
  },
}

const InboundConfig = ({ orderItem }: { orderItem: OrderItemType }) => {
  const config: configType = orderItem.config
  type dayKey = keyof typeof config.business_days

  return <Grid container spacing={2}>
    <Grid item sm={6}>
      <Typography variant='subtitle2'>Inbound number</Typography>
      <Typography sx={{ pl: 3 }}>{config.inbound_number}</Typography>
    </Grid>
    <Grid item sm={6}>
      <Typography variant='subtitle2'>Business hour routes</Typography>
      {config.routing_business_hours.map((row, key) => {
        return <Typography sx={{ pl: 3 }} key={key}>({key + 1}) {row.routing_number ?? row.routing_order_item_id} after {row.answered_after} seconds</Typography>
      })}
    </Grid>
    {config.routing_type === 'time_based' && config.business_days && <Grid item sm={6}>
      <Typography variant='subtitle2'>Business Hours</Typography>
      <Typography sx={{ pl: 3 }}>Day: {Object.keys(config.business_days).map(day => {
        const convertedKey = day as dayKey
        if (config.business_days && config.business_days[convertedKey] === 1) {
          return <Fragment key={day}>{day} </Fragment>
        }
        else {
          return <Fragment key={day}></Fragment>
        }

      })}</Typography>
      <Typography sx={{ pl: 3 }}>Time: {config.business_hour_from} to {config.business_hour_to}</Typography>

    </Grid>
    }
    {config.routing_type === 'time_based' && config.routing_after_hours && <Grid item sm={6}>
      <Typography variant='subtitle2'>After hour routes</Typography>
      {config.routing_after_hours.map((row, key) => {
        return <Typography sx={{ pl: 3 }} key={key}>({key + 1}) {row.routing_number ?? row.routing_order_item_id} after {row.answered_after} seconds</Typography>
      })}
    </Grid>
    }
  </Grid>
}

export default InboundConfig