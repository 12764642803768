import { Box, Typography, Divider, Grid, Stack, Button, TextField, InputAdornment } from "@mui/material"
import { Fragment, useState, ReactElement, useRef } from "react"
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Controller } from "react-hook-form"
import { ReactComponent as VisaImage } from '../../assets/images/visacard.svg'
import { ReactComponent as MasterImage } from '../../assets/images/mastercard.svg'
import { ReactComponent as AmericanImage } from '../../assets/images/americanexpress.svg'
import InputMask from 'react-input-mask';

const crossLineStyle = {
  background: 'linear-gradient(100deg, #F2F9FD calc(50% - 1px), #989898 , #F2F9FD calc(50% + 3px) )',
  width: '30px',
  marginLeft: '10px',
  marginRight: '10px'
}

const VerificationPayment = ({ control, errors, setValue, loading }: { control: any, errors: any, setValue: any, loading: boolean }) => {

  const [paymentType, setPaymentType] = useState('credit_card')
  const [creditCardMask, setCreditCardMask] = useState('9999 9999 9999 9999')
  const [creditCardTypeImg, setCreditCardTypeImg] = useState<ReactElement | null>(null)
  const valueRef = useRef<HTMLInputElement>()
  const updatePaymentType = (newPaymentType: string) => {
    setPaymentType(newPaymentType)
    setValue('payment_type', newPaymentType)
  }

  const onChangeMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    const maxLength = event.target?.maxLength;

    if (value.length >= maxLength) {
      valueRef?.current?.focus()
    }
  }

  const onChangeCcNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;

    if (/^3[47]/.test(value)) {
      setCreditCardMask('9999 999999 99999')
      setCreditCardTypeImg(<AmericanImage style={{ width: '100%' }} />)
    }
    else if (/^4/.test(value)) {
      setCreditCardTypeImg(<VisaImage style={{ width: '100%' }} />)
      setCreditCardMask('9999 9999 9999 9999')
    }
    else if (/^5/.test(value)) {
      setCreditCardTypeImg(<MasterImage style={{ width: '100%' }} />)
      setCreditCardMask('9999 9999 9999 9999')
    }
    else {
      setCreditCardTypeImg(null)
      setCreditCardMask('9999 9999 9999 9999')
    }
  }


  return <Fragment>
    <Grid item xs={12}><Typography variant='h4'>Payment details</Typography></Grid>
    <Grid item xs={12} sx={{ '@media (min-width: 600px)': { pl: 3 }, mt: 3 }}><Typography variant='h6'>Choose payment method</Typography></Grid>
    <Grid item xs={12} sx={{ '@media (min-width: 600px)': { pl: 3 }, mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={3}>
          <Button variant="outlined" onClick={() => { updatePaymentType('credit_card') }}
            disabled={loading}
            sx={{
              backgroundColor: paymentType === 'credit_card' ? '#E2F1FF' : '#F3F3F3',
              borderColor: paymentType === 'credit_card' ? '#0185FE' : '#989898',
              boxShadow: '0px 0px 6px #0000001A'
            }}
            size="large" fullWidth startIcon={<CreditCardIcon sx={{ color: paymentType === 'credit_card' ? "#0185FE" : "#B4B2B2" }} />}>
            <Typography variant="h5" color={paymentType === 'credit_card' ? "#0185FE" : "#B4B2B2"}>CREDIT CARD</Typography>
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Button variant="outlined" onClick={() => { updatePaymentType('bank_account') }}
            disabled={loading}
            sx={{
              backgroundColor: paymentType === 'bank_account' ? '#E2F1FF' : '#F3F3F3',
              borderColor: paymentType === 'bank_account' ? '#0185FE' : '#989898',
              boxShadow: '0px 0px 6px #0000001A'
            }}
            size="large" fullWidth startIcon={<AccountBalanceIcon sx={{ color: paymentType === 'bank_account' ? "#0185FE" : "#B4B2B2" }} />}>
            <Typography variant="h5" color={paymentType === 'bank_account' ? "#0185FE" : "#B4B2B2"}>BANK ACCOUNT
            </Typography></Button>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} sx={{ '@media (min-width: 600px)': { pl: 3 }, mt: 5 }}>
      {paymentType === 'credit_card' && <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            control={control}
            name='card_holder_name'
            render={({
              field: { value, onChange },
            }) => {
              return (
                <TextField value={value} disabled={loading} error={errors.card_holder_name ? true : false} onChange={onChange} variant="outlined" sx={{ backgroundColor: 'white' }} label="Card holder's name" fullWidth />
              );
            }}
          />
          {errors.card_holder_name && <Typography variant='error' sx={{ ml: 2 }}>{errors.card_holder_name.message}</Typography>}
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ '@media (min-width: 0px)': { mt: 0 }, '@media (min-width: 600px)': { mt: 3 }, '@media (min-width: 900px)': { mt: 0 } }}>
          <Controller
            control={control}
            name='card_number'
            render={({
              field: { value, onChange },
            }) => {
              return (
                <InputMask
                  mask={creditCardMask}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onChange(e); onChangeCcNumber(e) }}
                  value={value}
                  disabled={loading}
                >{
                    <TextField
                      error={errors.card_number ? true : false}
                      fullWidth
                      label="Credit Card Number"
                      name='card_number'
                      sx={{ backgroundColor: 'white' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box sx={{ display: 'flex', height: '60px', width: '50px', justifyContent: 'center', alignItems: 'center' }}>{creditCardTypeImg}</Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                </InputMask>
              );
            }}
          />
          {errors.card_number && <Typography variant='error' sx={{ ml: 2 }}>{errors.card_number.message}</Typography>}
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ '@media (max-width: 900px)': { mt: 3 } }}>
          <Typography sx={{ mt: '-27px' }}>Expiry date</Typography>
          <Stack direction='row'>
            <Box>
              <Controller
                control={control}
                name='card_expiry_month'
                render={({
                  field: { value, onChange },
                }) => {
                  return (
                    <TextField disabled={loading} type="text" variant="outlined" inputProps={{ maxLength: 2 }} error={errors.card_expiry_month ? true : false}
                      name='card_expiry_month' onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onChange(e); onChangeMonth(e) }} value={value} sx={{ backgroundColor: 'white' }} label="MM" fullWidth />
                  );
                }}
              />
              {errors.card_expiry_month && <Typography variant='error' sx={{ ml: 2 }}>{errors.card_expiry_month.message}</Typography>}
            </Box>


            <Box sx={{ mt: '5px', mb: '5px', maxHeight: '50px' }} style={crossLineStyle}></Box>
            <Box>
              <Controller
                control={control}
                name='card_expiry_year'
                render={({
                  field: { value, onChange },
                }) => {
                  return (
                    <TextField disabled={loading} inputRef={valueRef} type="text" variant="outlined" inputProps={{ maxLength: 2 }} error={errors.card_expiry_month ? true : false}
                      name='card_expiry_year' onChange={onChange} value={value} sx={{ backgroundColor: 'white' }} label="YY" fullWidth />
                  );
                }}
              />
              {errors.card_expiry_year && <Typography variant='error' sx={{ ml: 2 }}>{errors.card_expiry_year.message}</Typography>}

            </Box>
          </Stack>
        </Grid>
      </Grid>}
      {paymentType === 'bank_account' && <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Controller
            control={control}
            name='bank_account_name'
            render={({
              field: { value, onChange },
            }) => {
              return (
                <TextField disabled={loading} value={value} error={errors.bank_account_name ? true : false} onChange={onChange} variant="outlined" sx={{ backgroundColor: 'white' }} label="Account name" fullWidth />
              );
            }}
          />
          {errors.bank_account_name && <Typography variant='error' sx={{ ml: 2 }}>{errors.bank_account_name.message}</Typography>}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name='bank_account_bsb'
            render={({
              field: { value, onChange },
            }) => {
              return (
                <InputMask
                  mask='999-999'
                  onChange={onChange}
                  value={value}
                  disabled={loading}
                >{
                    <TextField
                      error={errors.bank_account_bsb ? true : false}
                      fullWidth
                      label="BSB"
                      name='bank_account_bsb'
                      sx={{ backgroundColor: 'white' }}
                    />
                  }
                </InputMask>
              );
            }}
          />
          {errors.bank_account_bsb && <Typography variant='error' sx={{ ml: 2 }}>{errors.bank_account_bsb.message}</Typography>}

        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name='bank_account_number'
            render={({
              field: { value, onChange },
            }) => {
              return (
                <TextField disabled={loading} value={value} error={errors.bank_account_number ? true : false} onChange={onChange} variant="outlined" sx={{ backgroundColor: 'white' }} label="Account number" fullWidth />
              );
            }}
          />
          {errors.bank_account_number && <Typography variant='error' sx={{ ml: 2 }}>{errors.bank_account_number.message}</Typography>}
        </Grid>

      </Grid>}
    </Grid>
    <Grid item xs={12} sx={{ mb: 4, mt: 3 }}><Divider /></Grid>
  </Fragment>
}

export default VerificationPayment