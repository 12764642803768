import { createBrowserRouter } from "react-router-dom";
//import RootLayout from '../../pages/RootLayout'
import ErrorPage from '../../pages/ErrorPage'
import VerificationLayout from '../../pages/verification/VerificationLayout'
import VerificationPage from '../../pages/verification/VerificationPage'
import VerificationLoader from '../../pages/verification/VerificationLoader'
import VerificationSuccess from "../../pages/verification/VerificationSuccess";


const Routes = () => {
  return createBrowserRouter([
    {
      errorElement: <ErrorPage />,
    },
    {
      element: <VerificationLayout />,
      children: [
        {
          path: "/verification/success",
          errorElement: <ErrorPage />,
          element: <VerificationSuccess />,
        },
        {
          path: "/verification/:uuid",
          loader: VerificationLoader,
          errorElement: <ErrorPage />,
          element: <VerificationPage />,
        }
      ]

    }


  ])
}

export default Routes()