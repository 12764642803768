import { Grid, Typography } from "@mui/material"
import type { orderItem as OrderItemType } from './VerificationType'

type configType = {
  inbound_number: string
}

const AnalytixConfig = ({ orderItem }: { orderItem: OrderItemType }) => {
  const config: configType = orderItem.config

  return <Grid container spacing={2}>
    <Grid item sm={4}>
      <Typography variant='subtitle2'>Indial Number</Typography>
      <Typography sx={{ pl: 3 }}>{config.inbound_number}</Typography>
    </Grid>
  </Grid>
}

export default AnalytixConfig