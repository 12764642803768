import { Link, useNavigate, useLoaderData, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Grid, Box, Typography, Divider, Paper, Checkbox, FormControlLabel, FormGroup, Button } from '@mui/material'
import { formatPrice } from '../../helpers/format'
import type { orderDetailInterface } from './VerificationType'
import type { verificationType } from '../../api/CustomerVerificationType'
import VerificationNewItem from './VerificationNewItem'
import VerificationChangeItem from './VerificationChangeItem'
import VerificationPayment from './VerificationPayment'
import * as VerificationApi from '../../api/CustomerVerificationApi'
import { useForm, Controller } from "react-hook-form"
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

const infoGridStyle = {
  '@media (min-width: 600px)': {
    mt: '5px',
  },
}

const infoGridHeaderStyle = {
  '@media (min-width: 0px)': {
    mt: '15px',
  },
  '@media (min-width: 600px)': {
    mt: '5px',
  },
}

const LinkStyle = {
  color: '#0185fe',
  textDecoration: 'none',
  '&:hover': { textDecoration: 'underline' }
}

const schema = Yup.object().shape({
  verifyChecked: Yup.boolean().required("Please agree to Alltel's Terms and Conditions to confirm your order.")
    .oneOf([true], "Please agree to Alltel's Terms and Conditions to confirm your order."),
  payment_required: Yup.boolean().required(),
  payment_type: Yup
    .string()
    .typeError("payment type is required"),
  card_number: Yup
    .string()
    .when(['payment_type', 'payment_required'], {
      is: (payment_type: string, payment_required: boolean) => {
        return payment_required === true && payment_type === 'credit_card'
      },
      then: (schema) => schema.required('credit card number is required')
    }),
  card_expiry_month: Yup
    .string()
    .when(['payment_type', 'payment_required'], {
      is: (payment_type: string, payment_required: boolean) => {
        return payment_required === true && payment_type === 'credit_card'
      },
      then: (schema) => schema.required('required').matches(/^(0[1-9]|1[0-2])$/, 'Invalid')
    }),
  card_expiry_year: Yup
    .string()
    .when(['payment_type', 'payment_required'], {
      is: (payment_type: string, payment_required: boolean) => {
        return payment_required === true && payment_type === 'credit_card'
      },
      then: (schema) => schema.required('required').matches(/^\d{2}$/, 'Invalid')
    }),
  card_holder_name: Yup
    .string()
    .when(['payment_type', 'payment_required'], {
      is: (payment_type: string, payment_required: boolean) => {
        return payment_required === true && payment_type === 'credit_card'
      },
      then: (schema) => schema.required('card holder name is required')
    }),
  bank_account_name: Yup
    .string()
    .when(['payment_type', 'payment_required'], {
      is: (payment_type: string, payment_required: boolean) => {
        return payment_required === true && payment_type === 'bank_account'
      },
      then: (schema) => schema.required('Bank account name is required')
    }),
  bank_account_bsb: Yup
    .string()
    .when(['payment_type', 'payment_required'], {
      is: (payment_type: string, payment_required: boolean) => {
        return payment_required === true && payment_type === 'bank_account'
      },
      then: (schema) => schema.required('Bank account bsb is required')
    }),
  bank_account_number: Yup
    .string()
    .when(['payment_type', 'payment_required'], {
      is: (payment_type: string, payment_required: boolean) => {
        return payment_required === true && payment_type === 'bank_account'
      },
      then: (schema) => schema.required('Bank account number is required').matches(/^\d+$/, 'Invalid number')
    }),
});

type schemaType = Yup.InferType<typeof schema>;

type verificationParam = {
  uuid: string;
};

const VerificationPage = () => {
  const orderDetails: orderDetailInterface = useLoaderData() as orderDetailInterface
  let { uuid } = useParams<verificationParam>();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors }, setValue, setError } = useForm<schemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      verifyChecked: orderDetails.provisioning_checklist.customer_verified === 1 ? true : false,
      payment_type: 'credit_card',
      card_number: '',
      card_expiry_month: '',
      card_expiry_year: '',
      card_holder_name: '',
      bank_account_name: '',
      bank_account_bsb: '',
      bank_account_number: '',
      payment_required: true,
    },
  });

  useEffect(() => {
    if (orderDetails.checklist_required.payment_verification_required === 1 && orderDetails.provisioning_checklist.payment_verified === 0) {
      setValue('payment_required', true)
    }
    else {
      setValue('payment_required', false)
    }

  }, [setValue, orderDetails.provisioning_checklist, orderDetails.checklist_required])


  const verifyOrder = async (props: schemaType) => {
    const payload: verificationType = {
      order_uuid: uuid,
      paymentMethod: props.payment_type === 'credit_card' ? 'creditCard' : 'bankAccount',
      cardNumber: props.card_number,
      cardholderName: props.card_holder_name,
      expiryDateMonth: props.card_expiry_month,
      expiryDateYear: props.card_expiry_year,
      accountNumber: props.bank_account_number,
      bsb: props.bank_account_bsb,
      accountName: props.bank_account_name
    }
    setLoading(true)
    try {
      await VerificationApi.verifyOrder(payload)
      navigate('/verification/success')
    }
    catch (e: any) {
      const keyName = Object.keys(e.response.data.errors)[0];
      if (keyName === 'expiryDateYear') {
        setError('card_expiry_year', { type: 'custom', message: e.response.data.errors[keyName] })
      }
      else if (keyName === 'expiryDateMonth') {
        setError('card_expiry_month', { type: 'custom', message: e.response.data.errors[keyName] })
      }
      else if (keyName === 'cardNumber') {
        setError('card_number', { type: 'custom', message: e.response.data.errors[keyName] })
      }
      else if (keyName === 'bsb') {
        setError('bank_account_bsb', { type: 'custom', message: e.response.data.errors[keyName] })
      }
      else if (keyName === 'accountNumber') {
        setError('bank_account_number', { type: 'custom', message: e.response.data.errors[keyName] })
      }
      else {
        setError('verifyChecked', { type: 'custom', message: e.response.data.errors[keyName] })
      }
    }
    setLoading(false)
  }

  return <>
  <Grid container>
  <Grid item xs={12} sx={{textAlign: 'right', pt: '10px', pr: '2px'}}><Typography variant='subtitle1' sx={{fontWeight: '700', fontStyle: 'italic'}}>Your order number #{orderDetails.order_id} was created on {orderDetails.order_date}</Typography></Grid>

  </Grid>
  <Grid container sx={{ p: 4, pt: 2, m: 'auto' }} maxWidth='1300px'>
    <Grid item xs={12}><Typography variant='h4'>Customer information</Typography></Grid>
    <Grid item xs={12} sx={{ mt: 1 }}>
      <Paper variant='outlined' sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12} sx={{ pb: 1 }}>
            <Box sx={{ height: '100%', background: '#F0FAFF 0% 0% no-repeat padding-box', borderRadius: '10px', pl: 2, pr: 2, pt: 1 }}>
              <Grid container>
                <Grid item xs={12}><Typography variant='h6' sx={{ color: '#0185FE' }}>Business details</Typography></Grid>
                <Grid item xs={12} sm={4} sx={infoGridHeaderStyle}><Typography variant="subtitle2">Business name</Typography></Grid>
                <Grid item xs={12} sm={8} sx={infoGridStyle}><Typography>{orderDetails.business_name}</Typography></Grid>
                <Grid item xs={12} sm={4} sx={infoGridHeaderStyle}><Typography variant="subtitle2">Trading name</Typography></Grid>
                <Grid item xs={12} sm={8} sx={infoGridStyle}><Typography>{orderDetails.trading_name}</Typography></Grid>
                <Grid item xs={12} sm={4} sx={infoGridHeaderStyle}><Typography variant="subtitle2">ABN/ACN</Typography></Grid>
                <Grid item xs={12} sm={8} sx={infoGridStyle}><Typography>{orderDetails.abn}</Typography></Grid>
                <Grid item xs={12} sm={12} sx={infoGridStyle}><Typography variant="subtitle2">&nbsp;</Typography></Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ pb: 1 }}>
            <Box sx={{ background: '#F0FAFF 0% 0% no-repeat padding-box', borderRadius: '10px', pl: 2, pr: 2, pt: 1, height: '100%' }}>
              <Grid container>
                <Grid item xs={12}><Typography variant='h6' sx={{ color: '#0185FE' }}>Contact details</Typography></Grid>
                <Grid item xs={12} sm={4} sx={infoGridHeaderStyle}><Typography variant="subtitle2">Name</Typography></Grid>
                <Grid item xs={12} sm={8} sx={infoGridStyle}><Typography>{orderDetails.first_name} {orderDetails.last_name}</Typography></Grid>
                <Grid item xs={12} sm={4} sx={infoGridHeaderStyle}><Typography variant="subtitle2">Address</Typography></Grid>
                <Grid item xs={12} sm={8} sx={infoGridStyle}><Typography>{orderDetails.business_address1} {orderDetails.business_address2}, {orderDetails.business_suburb} {orderDetails.business_postcode}, {orderDetails.business_state}</Typography></Grid>
                <Grid item xs={12} sm={4} sx={infoGridHeaderStyle}><Typography variant="subtitle2">Phone</Typography></Grid>
                <Grid item xs={12} sm={8} sx={infoGridStyle}><Typography>{orderDetails.mobile_number}</Typography></Grid>
                <Grid item xs={12} sm={4} sx={infoGridHeaderStyle}><Typography variant="subtitle2">Email</Typography></Grid>
                <Grid item xs={12} sm={8} sx={infoGridStyle}><Typography>{orderDetails.email}</Typography></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    {false && <Grid xs={12} sx={{ pl: '10px', mt: '10px' }}>
      <FormGroup>
        <FormControlLabel control={<Checkbox />} label={<Typography sx={{ fontWeight: 550 }}>Tick this box to verify</Typography>} />
      </FormGroup>
    </Grid>}
    <Grid item xs={12} sx={{ mt: 5 }}>
      <Grid container maxWidth='1100px' sx={{ m: 'auto' }}>
        <Grid item xs={12}><Typography variant='h4'>Order summary</Typography></Grid>
        {orderDetails.order_items.map((orderItem, key) => {
          if (orderItem.order_item_type === 'new') {
            return <VerificationNewItem key={key} orderItem={orderItem} />
          }
          else if (orderItem.order_item_type === 'change') {
            return <VerificationChangeItem key={key} orderItem={orderItem} />
          }
          else {
            return <></>
          }
        })}
        <Grid item xs={12}><Typography variant='h4'>Total charges summary</Typography></Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Paper variant='outlined'>
            <Grid container sx={{ p: 1, pt: 2, pb: 2, pr: 2 }}>
              <Grid item xs={6} sx={{ display: 'flex', pt: 1, pl: 2 }}>

              </Grid>
              <Grid item xs={3} sx={{ display: 'flex' }} alignItems='flex-end' justifyContent='flex-end'>
                <Typography variant='subtitle1' sx={{ color: '#0185FE' }}>SUBSCRIPTION</Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' } }} alignItems='flex-end' justifyContent='flex-end'>
                <Typography variant='subtitle1' sx={{ color: '#0185FE' }}>ONCE-OFF</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: '#989898' }} />
            <Grid container sx={{ p: 2 }}>
              <Grid item xs={6} sx={{ pl: 1 }}>
                <Typography sx={{ fontWeight: 700, display: 'inline' }}>Subtotal charges</Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex' }} justifyContent='flex-end'>
                <Typography sx={{ fontWeight: 700, display: 'flex', '@media (min-width: 600px)': { alignItems: 'flex-end' } }}>${formatPrice((+orderDetails.total_charges.customer_subscription_fee))}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex' }} justifyContent='flex-end'>
                <Typography sx={{ fontWeight: 700, display: 'flex', '@media (min-width: 600px)': { pr: '25px' } }}>${formatPrice((+orderDetails.total_charges.onceoff_fee))}</Typography>
              </Grid>
              {orderDetails.total_charges.discount_amount !== '0.00' && <>
                <Grid item xs={12} sx={{ pt: 2 }}><Divider /></Grid>
                <Grid item xs={6} sx={{ pl: 1, pt: 2 }}>
                  <Typography sx={{ display: 'inline', color: '#cf0000', fontWeight: 700 }}>Total discounts</Typography>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', pt: 2 }} justifyContent='flex-end' alignItems='flex-end'>
                  {orderDetails.total_charges.subscription_discount !== '0.00' && <Typography sx={{ color: '#cf0000', fontWeight: 700 }}>-${orderDetails.total_charges.subscription_discount}</Typography>}
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', pt: 2, '@media (min-width: 600px)': { pr: '25px' } }} justifyContent='flex-end' alignItems='flex-end'>
                  {orderDetails.total_charges.onceoff_discount !== '0.00' && <Typography sx={{ color: '#cf0000', fontWeight: 700 }}>-${orderDetails.total_charges.onceoff_discount}</Typography>}
                </Grid>
              </>}
            </Grid>
            <Divider sx={{ borderColor: '#989898' }} />
            <Grid container sx={{ p: 2 }}>
              <Grid item xs={6} sx={{ pl: 1 }}>
                <Typography variant='h6' sx={{ fontWeight: 700, '@media (min-width: 600px)': { display: 'inline' }, color: '#0185FE' }}>Total Charges </Typography>
                <Typography variant='subtitle1' sx={{ '@media (min-width: 600px)': { display: 'inline' }, color: '#0185FE' }}>(ex.GST)</Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex' }} justifyContent='flex-end'>
                <Typography variant='h6' sx={{ fontWeight: 700, display: 'flex', '@media (min-width: 600px)': { alignItems: 'flex-end' }, color: '#0185FE' }}>${formatPrice((+orderDetails.total_charges.customer_subscription_fee - +orderDetails.total_charges.subscription_discount))}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', '@media (min-width: 600px)': { pr: '25px' } }} justifyContent='flex-end'>
                <Typography variant='h6' sx={{ fontWeight: 700, display: 'flex', '@media (min-width: 600px)': { alignItems: 'flex-end' }, color: '#0185FE' }}>${formatPrice((+orderDetails.total_charges.onceoff_fee - +orderDetails.total_charges.onceoff_discount))}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>


  </Grid>
    <Grid container sx={{ backgroundColor: '#F2F9FD', pt: 5, pb: 5 }}>
      <Grid item xs={12}>
        <Grid container sx={{ p: 4, m: 'auto' }} maxWidth='1300px'>
          {orderDetails.provisioning_checklist?.payment_verified === 0 && <VerificationPayment setValue={setValue} control={control} errors={errors} loading={loading} />}
          <Grid item xs={12} sx={{ '@media (min-width: 600px)': { pl: 3 } }}>
            <Controller
              control={control}
              name='verifyChecked'
              render={({
                field: { value, onChange },
              }) => {
                return (
                  <FormGroup>
                    <FormControlLabel sx={{ alignItems: 'flex-start' }} control={<Checkbox disabled={loading || orderDetails.provisioning_checklist.customer_verified === 1} checked={value} onChange={onChange} sx={{ mt: -1 }} />} label={<Typography>By confirming this order, I agree to the Terms and Conditions as set by Alltel's
                      &nbsp;<Link style={LinkStyle
                      } target='_SFOA' to='https://www.alltel.com.au/docs/pdf/policies/Alltel-SFOA.pdf'>SFOA</Link>,
                      &nbsp;<Link style={LinkStyle
                      } target='_AUP' to='https://www.alltel.com.au/docs/pdf/policies/Alltel-Acceptable-Use.pdf'>Acceptable Use Policy</Link> and
                      &nbsp;<Link style={LinkStyle
                      } target='_PS' to='https://www.alltel.com.au/docs/pdf/policies/Alltel-Privacy-Statement.pdf'>Privacy Statement</Link>
                      . I agree to Alltel's Payment Terms and accept that my service subscriptions will be billed pro-rata and one month in advance.</Typography>} />
                  </FormGroup>
                );
              }}
            />
            {errors.verifyChecked && <Typography variant='error'>{errors.verifyChecked.message}</Typography>}
          </Grid>
          <Grid item xs={12} sm={5} md={3} sx={{ mt: 4, pl: 3 }}>
            {orderDetails.provisioning_checklist.customer_verified === 0 && <Button fullWidth disabled={loading} variant='contained' size='large' color='primary' endIcon={loading === true ? <CircularProgress size="20px" /> : ''} onClick={handleSubmit(verifyOrder)}>Confirm order</Button>}
            {orderDetails.provisioning_checklist.customer_verified === 1 && <><Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', fontStyle: 'italic' }}><CheckIcon color="success" /> Order Verified</Typography></>}

          </Grid>
        </Grid>
      </Grid>
    </Grid>

  </>
}

export default VerificationPage