import { useRouteError } from "react-router-dom";
import { Box, Stack, Typography } from '@mui/material';

const ErrorPage = () => {
  const error: any = useRouteError();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '500px'
    }}>
      <Stack alignItems='center' spacing="15px">
        <Typography variant="h5" >Sorry, an unexpected error has occurred!</Typography>
        <Typography variant="h4" sx={{color: 'red'}}>
          <i>{error.status} {error.statusText || error.message}</i>
        </Typography>
      </Stack>

    </Box>
  );
}

export default ErrorPage